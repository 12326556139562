<template>
  <div>
    The FEED ID: {{ feedId }}
    <b-table
      :sticky-header="false"
      small
      hover
      responsive
      selectable
      select-mode="single"
      class="position-relative"
      :per-page="postperPage"
      :current-page="postcurrentPage"
      :items="posts"
      :fields="postfields"
      :sort-by.sync="postsortBy"
      :sort-desc.sync="postsortDesc"
      :sort-direction="postsortDirection"
      :filter="postfilter"
      :filter-included-fields="postfilterOn"
    />
  </div>
</template>

<script>
import {
  ref, reactive, toRefs,
} from '@vue/composition-api'

import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    feedId: {
      type: String,
      default: null,
    },
  },

  setup() {
    const settings = reactive({
      postperPage: 100,
      postpageOptions: [5, 10, 20, 50, 100, 1000],
      posttotalRows: 1,
      postcurrentPage: 1,
      postsortBy: '',
      postsortDesc: false,
      postsortDirection: 'asc',
      postfilter: null,
      postfilterOn: [],
    })

    const postfields = ref([
      { key: 'id', label: 'Id' },
      { key: 'name', label: 'Name', sortable: true },
    ])

    return {
      postfields,
      ...toRefs(settings),
    }
  },
}

</script>
