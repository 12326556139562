import axiosIns from '@/libs/axios'

export function getAllFeeds() {
  return axiosIns.get('/feeds')
}

export function getFeedById(id) {
  return axiosIns.get(`/feeds/${id}`)
}

export function updateFeed(feed) {
  return axiosIns.patch(`/feeds/${feed.id}`, feed)
}
