<template>
  <b-card-code
    title="Feeds"
    no-body
  >
    <b-card-body>

      <!-- Feed Editor -->
      <!-- <div
        v-if="selected !== null"
        style="margin: 20px; width: 300px; height: auto; border: 1px solid #ccc;"
      >
        <tweet
          v-if="selected.post.postType === 'tweet'"
          :post="selected.post"
        />
      </div> -->
      <div>
        {{ selected }}
      </div>
      <!--/ Feed Editor -->

      <table-posts
        :posts="postitems"
        :feed-id="feedid"
      />

      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      :sticky-header="false"
      small
      hover
      responsive
      selectable
      select-mode="single"
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
    >

      <template #cell(enabled)="row">
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          v-model="row.item.enabled"
          @change="toggleEnabled"
        >
          {{ row.item.enabled ? 'Enabled' : 'Disabled' }}
        </b-form-checkbox>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BFormCheckbox, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

import {
  ref, reactive, computed, toRefs, onMounted,
} from '@vue/composition-api'

import { subject as an } from '@casl/ability'

import store from '@/store'

import * as FeedService from '../../services/FeedService'
import TablePosts from './TablePosts.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BFormCheckbox,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,

    TablePosts,
  },

  setup(props, context) {
    const settings = reactive({
      selected: null,
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      feedid: '',
    })

    const fields = ref([
      'Enabled',
      { key: 'id', label: 'Id' },
      { key: 'feedstatus', label: 'Status', sortable: true },
      { key: 'name', label: 'Name', sortable: true },
      { key: 'link', label: 'Link', sortable: true },
      { key: 'date', label: 'Date', sortable: true },
      { key: 'type', label: 'Type', sortable: true },
      { key: 'cat', label: 'Cat', sortable: true },
      { key: 'added', label: 'Added', sortable: true },
      { key: 'old', label: 'Old', sortable: true },
      { key: 'min', label: 'Min', sortable: true },
      { key: 'max', label: 'Max', sortable: true },
    ])

    const items = ref([
      // {
      //   id: 1,
      //   name: 'test name',
      //   link: 'http://somelinktotheaddressfortherss.com/rss',
      //   type: 1,
      //   enabled: true,
      // },
      // {
      //   id: 2,
      //   name: 'new  name',
      //   link: 'http://somelinktotheaddr.asda. essfortherss.com/rss',
      //   type: 2,
      //   enabled: true,
      // },
    ])

    const postitems = ref([
    ])

    const status = ref([
      {
        1: 'Published', 2: 'Pending', 3: 'Draft', 4: 'Archive',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-warning', 4: 'light-danger',
      },
    ])

    const getFeeds = () => {
      store.getters.isLoading = true

      FeedService.getAllFeeds()
        .then(response => {
          const feeds = response.data.data.data.map(item => an('Feed', item))

          // Set the initial number of items
          settings.totalRows = feeds.length

          /* eslint-disable global-require */
          feeds.forEach((feed, i) => {
            items.value.push(
              {
                feed,
                id: i + 1,
                feedstatus: feed.status,
                name: feed.name,
                link: feed.link,
                date: feed.processedDate,
                type: feed.feedType,
                cat: feed.categories.join(),
                enabled: feed.enabled,
                added: feed.numEntries,
                old: feed.numEntriesOld,
                min: feed.minAge.toFixed(2),
                max: feed.maxAge.toFixed(2),
              },
            )
          })
          /* eslint-disable global-require */
        })
        // .catch(error => {
        //   // console.log('\n\n Error====================================')
        //   // console.log(error)
        // })

      store.getters.isLoading = false
    }

    onMounted(getFeeds)
    // onMounted(() => {
    //   // Set the initial number of items
    //   settings.totalRows = items.value.length
    // })

    function toggleEnabled() {
      // TODO: Delete this line, no need to toggle since checkbox is already bound and causes
      // the toggle. The only thing in here is to set the enabled status with a database call.
      // items.value[0].enabled = !items.value[0].enabled
      // console.log(items.value[0].enabled)
    }

    function onRowSelected(rows) {
      if (rows.length > 0) {
        [settings.selected] = rows

        // console.log('------------------------rows----------------------')
        // console.log(settings.selected)
        const { feed } = rows[0]
        postitems.value = []
        settings.feedid = feed.id

        /* eslint-disable global-require */
        feed.postIds.forEach((post, i) => {
          postitems.value.push(
            {
              id: i + 1,
              name: post,
            },
          )
        })
        /* eslint-disable global-require */
      } else {
        settings.selected = null
      }
    }
    function info(item, index, button) {
      settings.infoModal.title = `Row index: ${index}`
      settings.infoModal.content = JSON.stringify(item, null, 2)
      context.emit('bv::show::modal', settings.infoModal.id, button)
    }
    function resetInfoModal() {
      settings.infoModal.title = ''
      settings.infoModal.content = ''
    }
    function onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      settings.totalRows = filteredItems.length
      settings.currentPage = 1
    }

    const sortOptions = computed(() => fields.value.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    return {
      fields,
      status,
      items,

      postitems,

      ...toRefs(settings),
      sortOptions,
      onRowSelected,
      info,
      resetInfoModal,
      onFiltered,
      getFeeds,
      toggleEnabled,
    }
  },
}

</script>
